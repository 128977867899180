
export const language = {
  "bank": "banca",
  "Start the game": "Incominciare il gioco",
  "Start new game": "Iniziare nuovo gioco",
  "Continue game": "Continuare il gioco",
  "End game": "Finire il gioco",
  "Round":  "Turno",
  "Income": "Ricavo",
  "Losses": "Perdite",
  "Profit": "Beneficio",
  "Players": "Giocatori",
  "Add name": "Aggiungere nome",
  "Appointments": "Appuntamento",
  "Bank data": "Dati bancari",
  "Is not in debt": "Non ha debiti",
  "Has borrowed a total of": "Ha prestato un importo di",
  "and written off": "e ammortizzato",
  "Owes": "Deve",
  "m.u.": "u.m", // monetary unit
  "in interest and": "in interessi e", // Owes 8 m.u. 2 in interest and 6 in capital
  "in capital": "in capitale",  // Owes 8 m.u. 2 in interest and 6 in capital
  "Write off": "Ammortizza",
  "New loan": "Nuovo prestito",
  "Has payed": "Ha pagato",
  "in interest":  "di interessi",
  "Has payed interest": "Ha pagato gli interessi",
  "The bank is owed": "Deve alla Banca",
  "Collected": "Raccolto",
  "Finish appointment": "Finire l'appuntamento",
  "Resuscitate the dead": "Resuscitare the dead",
  "Update": "Aggiornare",
  "Start the clock": "Fai partire il cronometro",
  "Stop the clock": "Fermare il cronometro",
  "About": "A proposito",
  "Code": "Codice",
  "Art work": "Grafica",
  "Download images": "Scarica immagini",
  "Donate": "Dona",
  "Help us to develop and share this software!": "Aiutaci a sviluppare e a condividere questo software!",
  "Licenses": "Licenze",
}
