
<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'BarChart',
  extends: Bar,
  mixins: [reactiveProp],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              type: 'linear',
              beginAtZero: true,
              precision: 0,
            }
          }
        ]
      }
    }
  }),

  mounted () {
    //console.log("mounted this.renderChart")
    this.renderChart(this.chartData, this.options)
  },
}
</script>

<style>

</style>
