<template>

  <v-container fill-height fluid class="queue">
    <v-row

      justify="center"
    >
      <v-col cols="3">

        <v-card
          class="mx-auto"
          max-width="400"
          outlined
        >

          <v-card-title
            class="justify-center white--text success"
          >
            <div style="font-size: 1.15em">
              <div v-if="clock_state != 'running'">
                <span class="mr-3">{{ $t('Appointments') }}</span>
                {{ updated_appointments_data.length }}
                  /
                {{ interviewees.length }}
              </div>
              <div v-else>
                {{ $t('Bank data') }}
              </div>
            </div>
          </v-card-title>

          <v-virtual-scroll
              :items="interviewees"
              :item-height="40"
              height="408"
            >
            <template v-slot:default="{ item }"  >
              <v-list-item
                @click="interview(item)"
              >
                <v-icon
                  v-if="clock_state !='running'"
                  :class="{'success--text' : has_been_interviewed(item)}"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-if="appointment_type =='default' &&
                        clock_state !='running'"
                  :class="[{'red--text': is_deceased(item)}]"
                >
                  mdi-emoticon-dead
                </v-icon>
                <v-icon
                  v-if="clock_state =='running'"
                >
                  mdi-chart-box
                </v-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="ml-3"
                  >
                    {{ item }}
                  </v-list-item-title>
                </v-list-item-content>

              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>

      </v-col>
      <v-col cols="5">

        <Appointment
          v-if="clock_state != 'running'"
          :input_data="current_player"
          :interview_in_progress="interview_in_progress"
          @appointmentFinished="appointmentFinished"
        />
        <PlayerStats
          v-else-if="current_player.name !== undefined"
          :player_name="current_player.name"
        />

        <div v-if="interview_in_progress == false">
          <div v-if="game_state == 'ready_to_end_game'"
              class="text-center" style="font-size: 3em;">
              Ready to end game
              <PlayingCard ></PlayingCard>
          </div>
          <div v-if="appointment_type == 'recover_the_dead'"
              class="text-center" style="font-size: 3em;">
              New credit
              <PlayingCard ></PlayingCard>
          </div>
          <!--
          <div  v-else-if="completed_interview_names.length >= names.length &&
                           clock_state == 'stopped'"
                class="text-center" style="font-size: 3em;">
                place_name
                <PlayingCard :width="600"></PlayingCard>

          </div>
          -->
          <div  v-else-if="completed_interview_names.length > 0 &&
                           completed_interview_names.length < names.length"
                class="text-center" style="font-size: 3em;">
                Next!

          </div>
          <div  v-else-if="completed_interview_names.length == 0 &&
                           clock_state != 'running'"
                class="text-center" style="font-size: 3em;">
            Bank is open!
            <PlayingCard ></PlayingCard>
          </div>

          <div v-if="clock_state == 'ready_to_start'">
            <PlayingCard ></PlayingCard>
          </div>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapState } from 'vuex'
import Appointment from '@/components/Appointment';
import PlayingCard from '@/components/PlayingCard';
import PlayerStats from '@/components/PlayerStats';
export default {
  name: 'Queue',
  components: {
    Appointment,
    PlayingCard,
    PlayerStats,
  },
  props: {
    //round_cnt: Number,
    //current_round: Array,
  },
  data: () => ({
    updated_appointments_data: [],
    completed_interview_names: [],  // used only for list display icon
    //dead_player_names: [], // used only for list display icon
    current_player: {},
    interview_in_progress: false,
    chart_data: Array,
  }),
  mounted() {
    if (this.stored_appointments_data !== undefined) {
      this.updated_appointments_data = this.stored_appointments_data
    }
  },
  watch: {
    appointments_data: function () {
      // new round has been started
      this.updated_appointments_data = []
      this.completed_interview_names = []
      //this.dead_player_names = []
      this.current_player = {}
      this.interview_in_progress = false
    },
    updated_appointments_data: function () {
      this.completed_interview_names = []
      //this.dead_player_names = []
      var self = this
      this.updated_appointments_data.forEach(function(appointment_data) {
        self.completed_interview_names.push(appointment_data.name)
        //if (appointment_data.is_dead) {
        //  self.dead_player_names.push(appointment_data.name)
        //}
      })
    },
  },
  methods: {
    interview: function(name) {
      var self = this
      if (this.clock_state == 'running') {
        this.appointments_data.forEach(function(player) {
          if (player.name == name) {
              self.current_player = player
          }
        })
        return
      }
      if (this.has_been_interviewed(name)) {
        //console.log("getting from this.updated_appointments_data")
        this.updated_appointments_data.forEach(function(player) {
          if (player.name == name) {
              self.current_player = player
          }
        })
      } else {
        //console.log("getting from this.appointments_data")
        this.appointments_data.forEach(function(player) {
          if (player.name == name) {
            self.current_player = player
          }
        })
      }
      self.interview_in_progress = true
    },
    appointmentFinished: function(updated_player) {
      this.interview_in_progress = false
      //this.appointment_cnt = this.appointment_cnt + 1
      var updated = false
      //console.log("updated_player")
      //console.log(updated_player)
      //console.log(this.updated_appointments_data)
      for(let i = 0; i < this.updated_appointments_data.length; i++){
          if (this.updated_appointments_data[i].name == updated_player.name) {
            this.updated_appointments_data[i] = updated_player
            updated = true
          }
      }
      if (updated == false) {
        this.updated_appointments_data.push(updated_player)
      }
      if (updated_player.is_dead == true) {
        this.addToDeadList(updated_player.name)
      } else if (this.appointment_type != "recover_the_dead") {
        // We will reset the dead_players lists when appointments have finnished
        this.removeFromDeadList(updated_player.name)
      }
      // check to see if all appointments are complete
      if (this.updated_appointments_data.length == this.appointments_data.length) {
        if (this.appointment_type == "recover_the_dead") {
          this.$store.commit('saveUpdatedDeadPlayersRecoveryData', this.updated_appointments_data)
        } else {
          this.$store.commit('saveUpdatedRoundData', this.updated_appointments_data)
        }
        // check to see if all players are dead
        if (this.appointments_data.length == this.dead_player_names.length) {
          if (this.appointment_type != "recover_the_dead") {
            this.$store.commit('setGameState', 'ready_to_end_game')
          }
          this.$store.commit('setAppointmentsState', 'completed')
        }
      }
      this.$store.commit("saveUpdatedAppointmentsData", this.updated_appointments_data)
    },
    has_been_interviewed: function(name) {
      //console.log("does include name: "+ name)
      //console.log(this.completed_interview_names.includes(name))
      //console.log(this.completed_interview_names)
      if (this.completed_interview_names.includes(name)) {
        return true
      } else {
        return false
      }
    },
    addToDeadList: function(name) {
      //console.log("adding dead to store: " + "'+"+name+"'")
      this.$store.commit('addDeadPlayer', name)
    },
    removeFromDeadList: function(name) {
      //console.log("removing dead to store")
      this.$store.commit('removeDeadPlayer', name)
    },
    is_deceased: function(name) {
      if (this.dead_player_names.includes(name)) {
        return true
      } else {
        return false
      }
    },

  },
  computed: {
    names: function () { return this.$store.state.names },
    interviewees: function () { return this.$store.getters.interviewees },
    appointments_data: function () {
      return this.$store.getters.current_appointments_data
    },
    rounds: function () { return this.$store.state.rounds },
    dead_player_names: function () { return this.$store.state.dead_players },
    clock_state: function () { return this.$store.state.clock_state },
    game_state: function () { return this.$store.state.game_state },
    appointments_state: function () { return this.$store.state.appointments_state },
    appointment_type: function () { return this.$store.state.appointment_type },
    stored_appointments_data: function () { return this.$store.state.appointments_data },
  },
}
</script>

<style lang="scss" scoped>
.queue {
  margin-top: -25px;
  //margin-top: unquote("calc(1%)") !important;
}
.v-virtual-scroll .v-icon {
  color: lightgray;
}
</style>
