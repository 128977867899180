import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const en = require('./translations/en.js');
const es = require('./translations/es.js');
const ca = require('./translations/ca.js');
const fr = require('./translations/fr.js');
const it = require('./translations/it.js');

const messages = {
  en: en.language,
  es: es.language,
  ca: ca.language,
  fr: fr.language,
  it: it.language,
}

const english = 'en';
export const i18n = new VueI18n({
  locale: english,
  fallbackLocale: english,
  messages
});
