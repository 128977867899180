<template>
  <div>
    <ClientHeader
      appointment_type="stats"
      :name="player_name"
    />
    <BarChart
      :chartData="chart_data"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ClientHeader from '@/components/ClientHeader';
import BarChart from '@/components/graphs/BarChart';

export default {
  name: 'PlayerStats',
  components: {
    ClientHeader,
    BarChart,
  },
  data: () => ({

  }),
  props: ['player_name'],

  computed: {
    chart_data: function () {
      var player_data = []
      var self = this
      this.rounds.forEach(function(round) {
        round.forEach(function(player_round_data) {
          if (player_round_data.name == self.player_name) {
            player_data.push(player_round_data)
          }
        });
      });
      var labels = []
      var debt = 0
      var debt_data = []
      //var written_off = 0
      var interest_data = []
      var total_interest = 0
      player_data.forEach(function(round, index) {
        labels.push(index + 1)
        //var total_debt = round.total_borrowed - round.total_written_off
        if (round.is_dead) {
          //written_off = 0
          debt = round.new_loan
        } else {
          //written_off = written_off + round.written_off
          //debt = debt + round.new_loan - written_off
          debt = debt + round.new_loan
          //debt = round.current_debt
          //debt = round.total_borrowed - round.total_written_off
        }
        debt_data.push(debt - round.total_written_off)
        total_interest = total_interest + round.interest
        interest_data.push(total_interest)
      });
      var datasets = [
        {
          label: 'Total debt',
          backgroundColor: "#4caf50",
          data: debt_data
        },
        {
          label: 'Total winnings',
          backgroundColor: "#D150D8",
          data: interest_data
        }
      ]
      return {
        type: 'Bar',
        labels: labels,
        datasets: datasets,
      }
    },
    ...mapState({
    rounds: state => state.rounds,
    })
  }
}
</script>

<style>

</style>
