<template>
<v-app>
  <NavBar />

  <v-main>
    <div class="mr-8 text-right">
      {{ $t('Income') }} : {{ winnings }}
      <span class="ml-4">{{ $t('Losses') }} : {{ losses }}</span>
      <span class="ml-4">{{ $t('Profit') }} : {{ winnings - losses }}</span>
    </div>

    <router-view
      :lables="names"
    />
  </v-main>

  <v-footer app padless fixed height="35">
    <v-col
      class="text-center"
      cols="12"
    >
    <ul class="lang_links">
      <li @click="changeLanguage('ca')">Català</li>
      <li @click="changeLanguage('en')">English</li>
      <li @click="changeLanguage('es')">Español</li>
      <li @click="changeLanguage('fr')">Français</li>
      <li @click="changeLanguage('it')">Italiano</li>
    </ul>
  </v-col>
</v-footer>
</v-app>

</template>

<script>
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar';
import { i18n } from '@/i18n';

const PLAYER_DATA = {
  name: String,
  total_borrowed: 0,
  written_off: 0,
  total_written_off: 0,
  current_debt: 0,
  interest: 0,
  new_loan: 0,
  is_interest_payed: false,
  is_dead: false,
  has_been_interviewed: false,
  collected_on_death: 0,
}

export default {
  name: 'App',
  i18n,
  components: {
    NavBar,
  },
  data() {
    return {
      round_data: [],
    }
  },
  mounted() {
    this.$router.push({ name: 'Home' })
  },
  watch: {
    game_state: function () {
      if (this.game_state == 'started') {
        if (this.appointments_state != 'completed') {
          console.log("this.game_state == 'started'")
          this.$store.commit('setAppointmentsState', 'started')
        }
      }
      if (this.game_state == 'ready_to_end_game') {
        console.log("this.game_state == 'ready_to_end_game'")
      }
      if (this.game_state == 'game_over') {
        this.$store.commit('setAppointmentsState', 'stopped')
        this.saveRound()
        this.$router.push({ name: 'Home' })
        this.$store.commit("resetStorage")
      }
    },
    appointments_state: function () {
      console.log("this.appointments_state: " + this.appointments_state)

      if (this.appointments_state == 'started') {
        if (this.rounds.length == 0) {
          this.$store.commit('setCurrentRound', this.getNewRoundData())
        }
        if (this.$route.name !== 'Queue') {
          this.$router.push({ name: 'Queue' })
        }
      }
      if (this.appointments_state == 'saved') {
          this.saveRound()
          this.$store.commit('setCurrentRound', this.getNewRoundData())
      }
      if (this.appointments_state == "dead_players_completed") {
          this.mergeDeadPlayersDataWithCurrentRound()
          this.$store.commit('setAppointmentsState', 'stopped')
          this.$store.commit('setAppointmentType', 'default')
      }
    },
    appointment_type: function () {
      if (this.appointment_type == 'recover_the_dead') {
        var data = this.initDeadPlayersAppointentData()
        this.$store.commit('saveDeadPlayersRecoveryData', data)
        this.$store.commit('setAppointmentsState', 'started')
      }
    },
    clock_state: function () {
      if (this.clock_state == "running") {
        this.$store.commit('setAppointmentType', 'stats')
        this.$store.commit('resetDeadPlayers')
      }
      if (this.clock_state == "stopped") {
        this.$store.commit('setAppointmentType', 'default')
        this.$store.commit('incRoundCount')
      }
    }
  },
  methods: {
    restoreGame: function () {
      if (localStorage.getItem('saved_game'))
        var storage = JSON.parse(localStorage.getItem('saved_game'));
        console.log(storage)
        alert(1)
    },
    saveRound: function () {
      var round_data = this.updated_round_data
      console.log("saveRound();")
      console.log(round_data)
      // save bank winnings and losses
      var winnings = 0
      var losses = 0
      round_data.forEach(function(player) {
        if (player.is_dead) {
          if (player.collected_on_death < player.current_debt) {
            // the bank has lost
            losses = losses + (player.current_debt - player.collected_on_death)
          } else {
            winnings = winnings + parseInt(player.collected_on_death)
          }
          // born again
          //player.is_dead = false
          player.total_borrowed = 0
          player.total_written_off = 0
          player.current_debt = 0
          player.collected_on_death = 0
          player.new_loan = 0
        } else {
          //winnings = winnings + player.written_off
          if (player.is_interest_payed == true) {
            winnings = winnings + player.interest
          }
          player.total_written_off = player.total_written_off + player.written_off
        }
      })
      this.$store.commit('addWinnings', winnings)
      this.$store.commit('addLosses', losses)
      this.$store.commit('commitUpdatedRoundData', round_data)
    },
    getNewRoundData: function () {
      var new_round = []
      var self = this
      this.names.forEach(function(name) {
        var player = self.playerData(name)
        // calculate player's totals
        var total_borrowed = player.total_borrowed + player.new_loan
        //var total_written_off = player.total_written_off + player.written_off
        //var current_debt = total_borrowed - total_written_off
        var current_debt = total_borrowed - player.total_written_off
        // calculate interest
        var interest = 0
        if (current_debt > 0) {
          interest = current_debt / 3
        }
        var data = Object.assign({}, PLAYER_DATA, {
                                  name: player.name,
                                  interest: interest,
                                  total_borrowed: total_borrowed,
                                  total_written_off: player.total_written_off,
                                  current_debt: current_debt,
                                }
                              )
        new_round.push(data)
      })
      //console.log(this.$store.state.rounds)
      return new_round
    },
    initDeadPlayersAppointentData: function () {
      var recovery_data = []
      this.dead_players.forEach(function(name) {
        var data = Object.assign({}, PLAYER_DATA, {
                                  name: name,
                                })
        recovery_data.push(data)
      });
      //console.log("recovery_data")
      //console.log(recovery_data)
      return recovery_data
    },
    mergeDeadPlayersDataWithCurrentRound() {
      var merged_round = []
      var self = this
      var merged=false
      this.current_round.forEach(function (player){

        self.dead_players_recovery_data_updated.forEach(function (recovered_player){
          if (player.name == recovered_player.name) {
            var interest = 0
            if (recovered_player.new_loan > 0) {
              interest = recovered_player.new_loan / 3
            }
            var reinsertionData = Object.assign({}, PLAYER_DATA, {
                                      name: player.name,
                                      interest: interest,
                                      current_debt: recovered_player.new_loan,
                                      total_borrowed: recovered_player.new_loan,
                                    }
                                  )
            merged_round.push(reinsertionData)
            merged = true
          }
        })
        if (merged == false) {
          merged_round.push(player)
        }
        merged = false
      })
      this.$store.commit('mergeIntoCurrentRound', merged_round)
    },
    playerData: function (name) {
      var result = PLAYER_DATA
      result.name = name
      if (this.rounds.length == 0) {
        return result;
      }
      var prev_round = this.rounds[this.rounds.length -1]
      prev_round.forEach(function(player) {
        if (player.name == name) {
          result = player
        }
      });
      return result
    },
    changeLanguage: function (lang) {
      this.$i18n.locale = lang;
    }
  },
  computed: mapState({
    names: state => state.names,
    dead_players: state => state.dead_players,
    //interviewees: function () { return this.$store.getters.interviewees },
    rounds: state => state.rounds,
    current_round: state => state.current_round,
    //dead_players_recovery_data: state => state.dead_players_recovery_data,
    dead_players_recovery_data_updated: state => state.dead_players_recovery_data_updated,
    updated_round_data: state => state.updated_round,
    winnings: state => state.winnings,
    losses: state => state.losses,
    game_state: state => state.game_state,
    appointments_state: state => state.appointments_state,
    appointment_type: state => state.appointment_type,
    clock_state: state => state.clock_state,
    game_over: state => state.game_over,
  }),
}
</script>

<style>
html {overflow-y: hidden;}
.lang_links {
  list-style-type: none;
  margin-top: -5px;

}
.lang_links li {
  cursor: pointer;
  display: inline;
  float: center;
  padding: 2px;
}
.lang_links li:hover {
  text-decoration: underline;
}
</style>
