<template>

  <v-img
    alt="Geconomicus Name"
    class="shrink mt-0 d-flex align-center"
    max-height="400px"
    :src="require(`../assets/cards/card_${random_number}.jpeg`)"

  />
</template>


<script>
export default {
  name: 'PlayingCard',
  data() {
    return {
      random_number: Math.floor((Math.random() * 4) + 1),
    }
  },
  props: {
    width: Number,
  },
}

</script>
