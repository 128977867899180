<template>
  <v-container fill-height fluid >
    <v-row  class="text-center">
      <v-col cols="12">
        <h1 class="display-2 font-weight-bold mb-12">
          Ḡeconomicus - {{ $t('bank') }}
        </h1>

        <h1 v-if="game_state == 'game_over'"
            class="display-2 font-weight-bold mb-12">
          {{ $t('Profit') }}: {{ winnings - losses }}
        </h1>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
//import PlayingCard from '@/components/PlayingCard';
export default {
  components: {
    //PlayingCard,
  },
  name: 'Home',
  data() {
    return {
      //can_restore_game: false,
    }
  },
   computed: {
    ...mapState([
                'names',
                'winnings',
                'losses',
                'game_state',
              ])
  }
}
</script>
