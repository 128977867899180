<template>
  <v-card>
    <v-toolbar
      color="white"
      flat
    >
      <v-toolbar-items>
        <v-btn text color="purple"
        @click="help_page = 'help_1'"
        >
         Item 1
        </v-btn>
        <v-btn text color="purple"
        @click="help_page = 'help_2'"
        >
         Item 2
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn text color="purple"
        @click="help_page = 'about'"
        >
         {{ $t('About') }}
        </v-btn>
        <v-btn text color="purple"
        @click="help_page = 'licenses'"
        >
         {{ $t('Licenses') }}
        </v-btn>
        <v-btn text color="purple"
        @click="help_page = 'qr_code'"
        >
         {{ $t('Donate') }}
         <v-icon>mdi-qrcode</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <hr />

    <div v-if="help_page=='help_1'">
      <Help1></Help1>
    </div>
    <div v-if="help_page=='help_2'">
      <Help2></Help2>
    </div>
    <div v-if="help_page=='about'">
      <About />
    </div>
    <div v-if="help_page=='licenses'">
      <Licenses />
    </div>
    <div v-if="help_page=='qr_code'">
      <QRcode />
    </div>

  </v-card>
</template>

<script>
import Help1 from '@/components/help/Help1';
import Help2 from '@/components/help/Help2';
import About from '@/components/help/About';
import Licenses from '@/components/help/Licenses';
import QRcode from '@/components/help/QRcode';
export default {
  name: 'Help',
  components: {
    Help1,
    Help2,
    About,
    Licenses,
    QRcode,
  },
  data() {
    return {
      help_page: "help_1"
    }
  },
  methods: {
    close: function(){
      this.$emit('close')
    }
  },
}
</script>

<style>
hr { border-top: 1px; color: lightgray}
</style>
