<template>
<div>

  <div v-if="interview_in_progress == true">

        <ClientHeader
          :appointment_type="appointment_type"
          :name="client.name"
          :dead_state="client.is_dead"
          @toggle_death="toggle_death"
        />

        <div class="mt-1" style="font-size: 1.1em">

          {{ $t("Has borrowed a total of") }} {{ client.total_borrowed }}
          <span v-if="client.total_borrowed > 0">
          {{ $t("and written off") }} {{ client.total_written_off}}
          </span>

        </div>

        <div
          v-if="client.interest > 0"
          class="mt-3" style="font-size: 1.35em;"
        >
          {{ $t("Owes") }}
          <span style="font-size: 1.45em">
            {{ client.current_debt + client.interest }}
          </span>
          {{ $t("m.u.") }}
          <span style="font-size: 1.45em">
            {{ client.interest }}
          </span>
          {{ $t("in interest and") }}
          <span style="font-size: 1.45em">
            {{ client.current_debt }}
          </span>
          {{ $t("in capital") }}
        </div>

        <div
          v-else
          class="mt-3 mb-3 text-center" style="font-size: 1.95em;"
        >
          {{ $t("Is not in debt") }}
        </div>

        <div
          v-if="client.is_dead && client.interest > 0"
          class="mt-7 mb-7 text-center" style="font-size: 2em;"
        >
          {{ $t('The bank is owed') }} {{ total_debt }}

          <v-select
            class="mt-5"
            v-if="total_debt > 0"
            v-model="client.collected_on_death"
            :items="pay_all_debt_options"
            :label="$t('Collected')"
          ></v-select>
        </div>

        <div v-if="client.is_dead == false">

          <v-checkbox
            :disabled="client.current_debt == 0"
            :label="interest_checkbox_label"
            v-model="is_interest_payed"
            color="success"
            hide-details
          >
          </v-checkbox>

          <v-select
            class="mt-5"
            :disabled="can_write_off == false"
            v-model="client.written_off"
            :items="write_off_options"
            filled
            :label="$t('Write off')"

          ></v-select>

          <v-select
            class="mt-5"
            :disabled="can_make_new_loan == false || client.written_off != 0"
            v-model="client.new_loan"
            :items="[0, 3, 6, 9, 12]"
            filled
            :label="$t('New loan')"
            dense
          ></v-select>

        </div>

        <div class="text-center">

          <v-btn class="mt-5"
            @click="finishAppointment()"
            :disabled="can_finish_appointment"
            elevation="5"
            color="primary"
            raised
            rounded
            large
          >
            <span v-if="client.has_been_interviewed == false">
              {{ $t('Finish appointment') }}
            </span>
            <span v-else>
              {{ $t('Update') }}
            </span>

          </v-btn>

        </div>

      </div>

</div>
</template>

<script>
/*
const ROUND_DATA = {
  name: String,
  total_borrowed: 0,
  written_off: 0,
  total_written_off: 0,
  current_debt: 0,
  interest: 0,
  new_loan: 0,
  is_interest_payed: false,
  is_dead: false,
  has_been_interviewed: false,
  collected_on_death: 0,
}
*/
import { mapState } from 'vuex'
import ClientHeader from '@/components/ClientHeader'
export default {
  name: 'Appointment',
  components: {
    ClientHeader
  },
  props: {
    input_data: {},
    interview_in_progress: Boolean,
  },
  data: () => ({
    client: {},
  }),
  watch: {
    input_data: function () {
      //this.forceRerender()
      this.client = Object.assign({}, this.input_data)
    },
  },
  computed: {
    is_interest_payed: {
      get: function () {
        return this.client.is_interest_payed
      },
      set: function (bool) {
        this.client.is_interest_payed = bool
        if (this.is_interest_payed == false) {
          this.client.new_loan = 0
          this.client.written_off = 0
        }
      }
    },
    ...mapState(['appointment_type']),
    total_debt: function () {
      return this.client.current_debt + this.client.interest
    },
    write_off_options: function () {
      var result = [0]
      var i = 0
      if (this.client.current_debt > 0) {
        while (i < (this.client.current_debt)) {
          i = i + 3
          result.push(i)
        }
      }
      return result
    },
    pay_all_debt_options: function () {
      var result = [0]
      var i = 0
      var debt = this.client.total_borrowed + this.client.interest
      if (debt > 0) {
        while (i < debt) {
          i = i + 1
          result.push(i)
        }
      }
      return result
    },
    can_make_new_loan: function () {
      if (this.client.current_debt == 0) {
        return true
      }
      if (this.client.is_interest_payed == true) {
        return true
      }
      return false
    },
    can_write_off: function () {
      if (this.client.is_interest_payed == true &&
          this.client.total_borrowed > 0) {
        return true
      }
      return false
    },
    can_finish_appointment: function () {
      if (this.client.is_interest_payed ||
          this.client.current_debt == 0 ||
          (this.client.has_been_interviewed == true && this.client.is_dead == true) ||
          this.client.is_dead == true ) {
        return false
      }
      return true
    },
    interest_checkbox_label: function () {
      if (this.client.interest > 0) {
        return this.$t('Has payed') + " " + this.client.interest + " " + this.$t('in interest')
      }
      return this.$t('Has payed interest')
    }
  },
  methods: {
    forceRerender() {
        this.checkbox_key += 1;
    },
    finishAppointment: function () {
      //console.log('this.interview_in_progress = false')
      this.client.has_been_interviewed = true
      this.$emit('appointmentFinished', this.client)
    },
    toggle_death: function (dead_state) {
      this.client.is_dead = dead_state
      if (this.client.is_dead == false) {
        this.client.collected_on_death = 0
      }
    },
    toggle_interest_payed: function () {
      if (this.client.is_interest_payed == true) {
        this.client.is_interest_payed = false
        this.client.new_loan = 0
        this.client.written_off = 0
      } else {
        this.client.is_interest_payed = true
      }
      console.log(this.client.is_interest_payed)
    },
  },
}
</script>

<style lang="scss" scoped>



</style>
