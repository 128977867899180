<template>
  <v-app-bar
    app
    fixed
    color="#3b444b"
    dark
    flat
  >

  <div class="d-flex align-center">

  </div>
  <router-link :to="{ name: 'Home', params: {} }">
    <v-img
      alt="Geconomicus Name"
      class="mt-0"
      contain
      min-width="40"
      src="@/assets/logo.png"
      width="56"
    />
  </router-link>

  <div v-if="game_state != 'new' &&
             game_state != 'no_players'">
    <div class="ml-5" style="font-size: 1.5em">
      {{ $t('Round') }} {{ round_counter }}
    </div>

  </div>

  <v-spacer></v-spacer>

  <div v-if="$route.name == 'Home'">
    <v-btn
      @click="callForPlayers()"
      color="success"
      rounded
      large>
      {{ $t('Start new game') }}
    </v-btn>

    <v-btn
      v-if="can_restore_game && game_state != 'game_over'"
      @click="continueSavedGame()"
      class="ml-6"
      color="success"
      rounded
      large>
      {{ $t('Continue game') }}
    </v-btn>
  </div>

  <div v-else >

    <div>
      <v-btn
        v-if="game_state == 'ready_to_start' && $route.name == 'Players'"
        @click="startGame"
        color="success"
        large
        rounded>
      {{ $t("Start the game") }}
      </v-btn>
    </div>

    <div v-if="appointments_state == 'completed' &&
               game_state != 'ready_to_end_game' &&
               clock_state !='running'"
    >

      <v-btn
        v-if="dead_players.length == 0"
        @click="finnishAppointments()"
        color="success"
        large
        rounded>
        {{ $t("Start the clock") }}
      </v-btn>

      <v-btn
        v-else-if="call_for_dead_players_credit"
        @click="startInterviewingTheDead()"
        color="success"
        large
        rounded>
        {{ $t("Resuscitate the dead") }}
      </v-btn>

      <v-btn
        v-else
        @click="saveDeadPlayersData()"
        color="success"
        large
        rounded>
        {{ $t("Start the clock") }}
      </v-btn>

    </div>

    <v-btn
      v-if="game_state == 'ready_to_end_game'"
      @click="endTheGame()"
      color="success"
      large
      rounded>
      {{ $t("End game") }}
    </v-btn>

    <div v-if="clock_state == 'running'"
          style="display:flex"
    >
      <Clock
        style="font-size: 2.75em; color: white"
      >
      </Clock>
      <v-btn
        class="ml-5 mt-3"
        @click="stopTheClock()"
        color="success"
        large
        rounded>
       {{ $t('Stop the clock') }}
      </v-btn>
    </div>

  </div>

  <v-spacer></v-spacer>

     <v-dialog
       v-model="help_dialog"
       fullscreen
       hide-overlay
       transition="dialog-bottom-transition"
     >
       <template v-slot:activator="{ on, attrs }">
         <v-btn text
         v-bind="attrs"
         v-on="on"
         >
           <span class="mr-3"><v-icon>mdi-qrcode</v-icon></span>
           <v-icon>mdi-help-circle-outline</v-icon>
         </v-btn>
       </template>
       <Help
        @close="closeHelp"></Help>
     </v-dialog>

  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import Clock from '@/components/Clock';
import Help from '@/components/Help';

export default {
  name: 'NavBar',
  components: {
    Clock,
    Help,
  },
  data() {
    return {
      min_number_of_players: 3,
      can_restore_game: false,
      help_dialog: false,
    }
  },
  mounted() {
    var saved_game = JSON.parse(localStorage.getItem("saved_game")) || {}
    if (saved_game.committed_to_local_storage !== undefined) {
      this.can_restore_game = true
    }
  },
  methods: {
    callForPlayers: function () {
      this.$store.commit("resetGame")
      this.$store.commit('setGameState', 'no_players')
      this.$router.push({ name: 'Players' })
    },
    continueSavedGame: function () {
      var saved_game = JSON.parse(localStorage.getItem("saved_game")) || {}
      this.$store.replaceState(saved_game)
      if (this.game_state == "started" || this.game_state == "ready_to_end_game") {
        if (this.$route.name !== 'Queue') {
          this.$router.push({ name: 'Queue' })
        }
      } else {
        this.$router.push({ name: 'Players' })
      }
    },
    // We have Players, let's start!
    startGame: function () {
      this.$store.commit('setGameState', 'started')
      this.$store.commit("setNames", this.names.reverse())
      if (this.$route.name !== 'Queue') {
        this.$router.push({ name: 'Queue' })
      }
    },
    finnishAppointments() {
      this.$store.commit('setAppointmentsState', 'saved')
      this.$store.commit('setClockState', 'ready_to_start')
      this.startTheClock()
    },
    startInterviewingTheDead() {
      this.$store.commit('setAppointmentsState', 'saved')
      this.$store.commit('setAppointmentType', 'recover_the_dead')
    },
    saveDeadPlayersData() {
      this.$store.commit('setAppointmentsState', 'dead_players_completed')
      this.$store.commit('setClockState', 'running')
    },
    startTheClock(){
      this.$store.commit('setAppointmentsState', 'saved')
      this.$store.commit('setClockState', 'running')
    },
    stopTheClock() {
      this.help_dialog = false
      this.$store.commit('setClockState', 'stopped')
      this.$store.commit('setAppointmentsState', 'started')
    },
    endTheGame() {
      this.$store.commit('setGameState', 'game_over')
    },
    closeHelp() {
      this.help_dialog = false
    }
   },
   watch: {
    committed_to_local_storage: function () {
      this.can_restore_game = this.committed_to_local_storage
    },
   },
   computed: {
     is_start_game_visible () {
       if (this.is_clock_visible == true) {
         return false
       }
       if (this.names.length >= this.min_number_of_players
           && this.$route.name == 'Players') {
         return true
       }
       return false
     },
     call_for_dead_players_credit () {
       if (this.dead_players.length != 0 &&
           this.dead_players.length < this.names.length &&
           this.appointment_type != 'recover_the_dead') {
             return true
      }
      return false
     },
     appointments_data: function () {
       return this.$store.getters.current_appointments_data
     },
     ...mapState([
                  'names',
                  'dead_players',
                  //'current_round',
                  //'updated_round',
                  //'dead_players_recovery_data',
                  //'dead_players_recovery_data_updated',
                  'game_state',
                  'committed_to_local_storage',
                  'round_counter',
                  'appointments_state',
                  'appointment_type',
                  'clock_state'
                ])
   }
}
</script>

<style lang="scss">
.v-dialog--fullscreen {
 border-radius: 0;
 margin: 0;
 height: 100%;
 // height: unquote("calc(100% - 100px )") !important;
 position: fixed;
 overflow-y: auto;
 top: 65px !important;
 left: 0;
}
</style>
