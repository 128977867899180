<template>
    <div id="time" v-html="time"></div>
</template>

<script>
export default {
  name: 'Clock',
  data() {
        return {
            state: "started",
            startTime: Date.now(),
            currentTime: Date.now(),
            interval: null
        }
  },
  mounted: function() {
      this.interval = setInterval(this.updateCurrentTime, 1000);
  },
  destroyed: function() {
      clearInterval(this.interval)
  },
  computed: {
      time: function() {
        return this.minutes + ':' + this.seconds;
      },
      milliseconds: function() {
        return this.currentTime - this.startTime;
      },
      minutes: function() {
        var lapsed = this.milliseconds;
        var min = Math.floor((lapsed / 1000 / 60) % 60);
        if (Math.ceil((lapsed / 1000) % 60) == 61) {
          return min+1
        }
        return min;
      },
      seconds: function() {
          var lapsed = this.milliseconds;
          var sec = Math.ceil((lapsed / 1000) % 60);
          console.log(sec)
          if ( sec > 0 ) {
            sec = sec - 1
          }
          if (sec == 60) {
            return "00"
          }
          return sec >= 10 ? sec : '0' + sec;
      }
  },
  methods: {
      reset: function() {
          this.$data.state = "started";
          this.$data.startTime = Date.now();
          this.$data.currentTime = Date.now();
      },
      pause: function() {
          this.$data.state = "paused";
      },
      resume: function() {
          this.$data.state = "started";
      },
      updateCurrentTime: function() {
          if (this.$data.state == "started") {
              this.currentTime = Date.now();
          }
      }
  }
}
</script>

<style>

</style>
