
export const language = {
  "bank": "banc",
  "Start the game": "Començar el joc",
  "Start new game": "Començar un joc nou",
  "Continue game": "Continuar el joc",
  "End game": "Acabar el joc",
  "Round": "Ronda",
  "Income": "Ingressos",
  "Losses": "Pèrdues",
  "Profit": "Benefici",
  "Players": "Jugadors/es",
  "Add name": "Afegir nom",
  "Appointments": "Cites",
  "Bank data": "Dades bancàries",
  "Is not in debt": "No té deute",
  "Has borrowed a total of": "Ha agafat prestat un total de",
  "and written off": "i amortitzat",
  "Owes": "Deu",
  "m.u.": "u.m.", // monetary unit
  "in interest and": "en interessos i", // Owes 8 m.u. 2 in interest and 6 in capital
  "in capital": "de capital",  // Owes 8 m.u. 2 in interest and 6 in capital
  "Write off": "Amortitza",
  "New loan": "Nou préstec",
  "Has payed": "Ha pagat",
  "in interest": "en interessos",
  "Has payed interest": "Ha pagat interessos",
  "The bank is owed": "Deu al banc",
  "Collected": "Recaptat",
  "Finish appointment": "Finalitzar la cita",
  "Resuscitate the dead": "Ressuscitar els morts",
  "Update": "Actualitzar",
  "Start the clock": "Iniciar el rellotge",
  "Stop the clock": "Parar el rellotge",
  "About": "Sobre",
  "Code": "Codi",
  "Art work": "Treball artístic",
  "Download images": "Descarregar imatges",
  "Donate": "Donar",
  "Help us to develop and share this software!": "Ajuda'ns a desenvolupar i compartir aquest programari!",
  "Licenses": "Llicències",
}
