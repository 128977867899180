import Vue from 'vue'
import Vuex from 'vuex'
import Storage from "vue-ls"

Vue.use(Vuex, Storage)

function saveGameToLocalStorage(state) {
  state.committed_to_local_storage = true
  localStorage.setItem("saved_game", JSON.stringify(store.state));
}

const store = new Vuex.Store({
  state: {
    names: [],
    dead_players: [],  // when dead_players == names: game_state=ready_to_end_game
    dead_players_recovery_data: [],
    dead_players_recovery_data_updated: [],  // this is merged into the current_round[]
    rounds: [],
    round_counter: 1,
    current_round: [],  // contains prepared data before interviews start
    updated_round: [],  // contains data after all interviews have finished
    appointments_data: [],
    winnings: 0,
    losses: 0,

    // 'new':
    // 'no_players'
    // 'ready_to_start': The minimum required players have been added to Store
    // 'started': The game has started
    // 'ready_to_end_game': All players are dead
    // 'game_over': The game is over.
    game_state: 'new',

    // 'started': The bank is interviewing players
    // 'completed': All players have been intervied, but can be updated
    // 'saved': All interviews have finninshed.
    // 'dead_players_completed': dead players' new credits are merged into current_round
    // 'stopped'
    appointments_state: 'stopped',

    // 'default': Normal game play
    // 'stats': Show players' statistics
    // 'recover_the_dead':
    appointment_type: 'default',

    // 'ready_to_start': display clock
    // 'running'
    // 'stopped'
    clock_state: 'stopped',
    committed_to_local_storage: false,
  },
  mutations: {
    resetGame (state) {
      state.names = []
      state.dead_players = []
      state.dead_players_recovery_data = []
      state.dead_players_recovery_data_updated = []
      state.rounds = []
      state.round_counter = 1
      state.current_round = []
      state.updated_round = []
      state.winnings = 0
      state.losses = 0
      state.game_state = 'new'
      state.appointments_data = []
      state.appointments_state = 'stopped'
      state.appointment_type = 'default'
      state.clock_state = 'stopped'
      //state.game_saved = false
      localStorage.removeItem("saved_game")
    },
    setNames (state, names) {
      state.names = names
      saveGameToLocalStorage(state)
    },
    setGameState (state, game_state) {
      state.game_state = game_state
      saveGameToLocalStorage(state)
    },
    setAppointmentType (state, type) {
      state.appointment_type = type
      saveGameToLocalStorage(state)
    },
    setAppointmentsState (state, appointments_state) {
      state.appointments_state = appointments_state
      saveGameToLocalStorage(state)
    },
    saveDeadPlayersRecoveryData(state, data) {
      state.dead_players_recovery_data = data
      saveGameToLocalStorage(state)
    },
    saveUpdatedAppointmentsData(state, data) {
      state.appointments_data = data
      saveGameToLocalStorage(state)
    },
    setClockState (state, clock_state) {
      state.clock_state = clock_state
      saveGameToLocalStorage(state)
    },
    incRoundCount (state) {
      state.round_counter += 1
      saveGameToLocalStorage(state)
    },
    addWinnings (state, winnings) {
      state.winnings = state.winnings + winnings
      saveGameToLocalStorage(state)
    },
    addLosses (state, losses) {
      state.losses = state.losses + losses
      saveGameToLocalStorage(state)
    },
    addDeadPlayer(state, name){
      var new_arr = state.dead_players.filter(function(ele){
                                              return ele != name;
                                            });
      state.dead_players = new_arr
      state.dead_players.push(name)
      localStorage.setItem("saved_game", state.storage);
    },
    removeDeadPlayer(state, name) {
      var new_arr = state.dead_players.filter(function(ele){
                                              return ele != name;
                                            });
      state.dead_players = new_arr
      if (state.game_state == 'ready_to_end_game'){
        state.game_state = 'started'
      }
      saveGameToLocalStorage(state)
    },
    resetDeadPlayers(state){
      state.dead_players = []
      state.dead_players_recovery_data = []
      state.dead_players_recovery_data_updated = []
      saveGameToLocalStorage(state)
    },
    setCurrentRound(state, round) {
      state.current_round = round
      saveGameToLocalStorage(state)
    },
    saveUpdatedRoundData(state, round) {
      console.log("store.saveUpdatedRoundData()")
      state.updated_round = round
      if (state.names.length == state.dead_players.length) {
        state.game_state = 'ready_to_end_game'
      }
      else if (state.names.length == state.updated_round.length){
        state.appointments_state = 'completed'
      }
      saveGameToLocalStorage(state)
      console.log(state.appointments_state, state.appointment_type)
    },
    saveUpdatedDeadPlayersRecoveryData(state, dead_players_recovery_data) {
      state.dead_players_recovery_data_updated = dead_players_recovery_data
      if (state.dead_players_recovery_data.length == state.dead_players_recovery_data_updated.length){
        state.appointments_state = 'completed'
      }
      saveGameToLocalStorage(state)
    },
    mergeIntoCurrentRound(state, round) {
      state.current_round = round
      state.appointments_data = []
      saveGameToLocalStorage(state)
    },
    commitUpdatedRoundData(state, round) {
      console.log('committed round data')
      state.rounds.push(round)
      state.updated_round = []
      state.current_round = []
      if (state.game_state != 'ready_to_end_game') {
        state.appointments_state = 'completed'
      }
      state.appointments_data = []
      saveGameToLocalStorage(state)
    },
  },
  getters : {
    interviewees: state => {
      if (state.appointment_type == 'recover_the_dead') {
        return state.dead_players
      }
      return state.names
    },
    current_appointments_data: state => {
      if (state.appointment_type == 'recover_the_dead') {
        return state.dead_players_recovery_data
      } else {
        return state.current_round
      }
    },
  }
})

export default store
