
export const language = {
  "bank": "banco",
  "Start the game": "Comenzar el juego",
  "Start new game": "Comenzar un juego nuevo",
  "Continue game": "Continuar el juego",
  "End game": "Terminar el juego",
  "Round": "Ronda",
  "Income": "Ingresos",
  "Losses": "Pérdidas",
  "Profit": "Beneficio",
  "Players": "Jugadores/as",
  "Add name": "Añadir nombre",
  "Appointments": "Citas",
  "Bank data": "Datos bancarios",
  "Is not in debt": "No tiene deuda",
  "Has borrowed a total of": "Ha prestado un total de",
  "and written off": "y amortizado",
  "Owes": "Debe",
  "m.u.": "u.m.", // monetary unit
  "in interest and": "de interés y", // Owes 8 m.u. 2 in interest and 6 in capital
  "in capital": "de capital",  // Owes 8 m.u. 2 in interest and 6 in capital
  "Write off": "Amortiza",
  "New loan": "Nuevo préstamo",
  "Has payed": "Ha pagado",
  "in interest": "en intereses",
  "Has payed interest": "Ha pagado interés",
  "The bank is owed": "Debe al banco",
  "Collected": "Recaudado",
  "Finish appointment": "Finalizar la cita",
  "Resuscitate the dead": "Recusitar a los muertos",
  "Update": "Actualizar",
  "Start the clock": "Iniciar el reloj",
  "Stop the clock": "Parar el reloj",
  "About": "Sobre",
  "Code": "Código",
  "Art work": "Trabajo artístico",
  "Download images": "Download images",
  "Donate": "Donar",
  "Help us to develop and share this software!": "Ayúdanos a desarrollar y compartir este software!",
  "Licenses": "Licencias",
}
