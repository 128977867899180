
export const language = {
  "bank": "prêteur",
  "Start the game": "Start the game",
  "Start new game": "Start new game",
  "Continue game": "Continue game",
  "End game": "End game",
  "Round": "Round",
  "Income": "Revenu",
  "Losses": "Pertes",
  "Profit": "Bénéfices",
  "Players": "Players",
  "Add name": "Add name",
  "Appointments": "Appointments",
  "Bank data": "Bank data",
  "Is not in debt": "Is not in debt",
  "Has borrowed a total of": "Has borrowed a total of",
  "and written off": "and written off",
  "Owes": "Owes",
  "m.u.": "m.u.", // monetary unit
  "in interest and": "in interest and", // Owes 8 m.u. 2 in interest and 6 in capital
  "in capital": "in capital",  // Owes 8 m.u. 2 in interest and 6 in capital
  "Write off": "Write off",
  "New loan": "New loan",
  "Has payed": "Has payed",
  "in interest": "in interest",
  "Has payed interest": "Has payed interest",
  "The bank is owed": "The bank is owed",
  "Collected": "Collected",
  "Finish appointment": "Finish appointment",
  "Resuscitate the dead": "Resuscitate the dead",
  "Update": "Update",
  "Start the clock": "Start the clock",
  "Stop the clock": "Stop the clock",
  "About": "About",
  "Code": "Code",
  "Art work": "Art work",
  "Download images": "Download images",
  "Donate": "Donate",
  "Help us to develop and share this software!": "Help us to develop and share this software!",
  "Licenses": "Licenses",
}
