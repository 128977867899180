<template>
  <v-container>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">

        <div style="font-size: 2em">
          {{ $t('Players') }}: {{ names.length }}
        </div>

          <v-responsive
            class="mx-auto"
          >
            <v-text-field
              :label="$t('Add name')"
              v-model="new_name"
              @keyup.enter.native="addPerson"
            ></v-text-field>
          </v-responsive>

          <v-card
            flat
            class="mx-auto"
            height="100%"
          >
            <v-virtual-scroll
              :items="names"
              height="355"
              item-height="32"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item">

                  <v-list-item-action class="mr-4">
                    <v-icon
                      color="red darken-2"
                      @click="deletePerson(item)"
                    >
                      mdi-delete-circle
                    </v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                  </v-list-item-content>

                </v-list-item>

              </template>
            </v-virtual-scroll>
          </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Players',
  data: () => ({
    new_name: "",
    min_number_of_players: 1,
  }),
  methods: {
    addPerson: function () {
      if (this.new_name !== "" && ! this.names.includes(this.new_name)) {
        var _names = this.names
        _names.unshift(this.new_name);
        this.new_name = ""
        this.$store.commit('setNames', _names)
      }
      if (this.names.length >= this.min_number_of_players) {
        this.$store.commit('setGameState', 'ready_to_start')
      }
    },
    deletePerson: function (name) {
      var index = this.names.indexOf(name);
      if (index > -1) {
        var _names = this.names
        _names.splice(index, 1);
        this.$store.commit('setNames', _names)
      }
      if (this.names.length < this.min_number_of_players) {
        this.$store.commit('setGameState', 'no_players')
      }
    },
  },
  computed: mapState({
    names: state => state.names,
  }),
}
</script>

<style lang="scss" scoped>
.v-list-item {
  padding:0 !important;
}
</style>
