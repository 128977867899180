<template>
<div>
  <div style="margin-bottom: -6px;">
    <v-icon
      id="death_icon"
      v-if="appointment_type == 'default'"
      @click="toggle_death"
      large
      :class="dead_state && 'red--text'"
      style="margin-top: -15px;"
    >
      mdi-emoticon-dead
    </v-icon>
    <span style="font-size: 2.5em;">
      {{ name }}
    </span>
  </div>

  <hr class="mt-3 grey lighten-3"/>
</div>
</template>

<script>
export default {
  name: 'ClientHeader',
  props: {
    appointment_type: String,
    name: String,
    dead_state: Boolean,
  },
  methods: {
    toggle_death: function () {
      if (this.dead_state == true) {
        this.$emit('toggle_death', false);
      } else {
        this.$emit('toggle_death', true);
      }
    }
  }
}
</script>

<style>
#death_icon {
  color: gray;
}
</style>
